.content {
  margin-top:auto;
  margin-bottom:auto;
  text-align:center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("../../img/bg.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
